import { CUSTOM_CHART_COLORS, CUSTOM_CHART_OPTS } from './colors'
import { ICON_MAPPING } from './icons'

// export to global namespace
// to be used transparently by the 'client'
// and to be modified per branding
window.CUSTOM_CHART_COLORS = CUSTOM_CHART_COLORS
window.CUSTOM_CHART_OPTS = CUSTOM_CHART_OPTS
window.ICON_MAPPING = ICON_MAPPING

const EXTERNAL_LINKS = ['itwm.fraunhofer.de', 'www.wendeware.com', 'efre.rlp.de']

// Branding for the "LegacyApp"
window.LegacyApp || (window.LegacyApp = {})
window.LegacyApp.EXTERNAL_LINKS = EXTERNAL_LINKS

export function overwriteCustomAssignments(newAssigns, defaultAssigns) {
  for (var k in defaultAssigns) {
    if (!newAssigns[k]) {
      continue
    }
    if (Array.isArray(defaultAssigns[k])) {
      defaultAssigns[k] = newAssigns[k]
    } else if (typeof defaultAssigns[k] === 'object') {
      Object.assign(defaultAssigns[k], newAssigns[k])
    } else {
      defaultAssigns[k] = newAssigns[k]
    }
  }
}
