import * as battery0 from '@/../images/icons/default/battery0.svg'
import * as battery20 from '@/../images/icons/default/battery20.svg'
import * as battery40 from '@/../images/icons/default/battery40.svg'
import * as battery60 from '@/../images/icons/default/battery60.svg'
import * as battery80 from '@/../images/icons/default/battery80.svg'
import * as battery100 from '@/../images/icons/default/battery100.svg'
import * as chp from '@/../images/icons/default/chp.svg'
import * as container from '@/../images/icons/default/container.svg'
import * as fuelcell from '@/../images/icons/default/fuelcell.svg'
import * as generic from '@/../images/icons/default/generic.svg'
import * as grid from '@/../images/icons/default/grid.svg'
import * as heatingelement from '@/../images/icons/default/heatingelement.svg'
import * as heatpump from '@/../images/icons/default/heatpump.svg'
import * as pv from '@/../images/icons/default/pv.svg'
import * as rest from '@/../images/icons/default/rest.svg'
import * as trafo from '@/../images/icons/default/trafo.svg'
import * as user from '@/../images/icons/default/user.svg'
import * as wallbox from '@/../images/icons/default/wallbox.svg'

export const ICON_MAPPING = {
  battery: battery0,
  battery0,
  battery20,
  battery40,
  battery60,
  battery80,
  battery100,
  chp,
  container,
  fuelcell,
  generic,
  grid,
  heatingelement,
  heatpump,
  pv,
  rest,
  trafo,
  user,
  wallbox
}
